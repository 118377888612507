import {NavbarToggler, Nav, Collapse, Navbar, NavItem, NavLink, NavbarBrand} from 'reactstrap';
import React from "react";
import "./navBar.css";
import "./knapp.css";
import Knapp from "./../knapp/knapp";



export class NavBar extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            isOpen: false,
            collapsed: true,
        };
      };

      toggleNavbar () {
          this.setState({
            isOpen: !this.state.isOpen
          });
      };

        
        render () {
                    
return (
        <div className="navbar-wrapper"> 
          <Navbar className="erika" color="" light expand="md">
            <NavbarBrand href="/" className="vegabonty"></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink className="jelly-one" href="/">Inicio</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="jelly-two" href="/contacto">Contacto</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="jelly-three" href="/galeria">Galeria</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="jelly-four" href="/carrito">Carrito ({this.props.cartTotalAmount} cositas)</NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink className="mobilemenu" href="/compras">Politicas de venta</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="mobilemenu" href="/cookies">Cookies</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="mobilemenu" href="/avisolegal">Aviso Legal</NavLink>
                </NavItem> */}
              </Nav>
            </Collapse>
          </Navbar>             
        </div>         
         )
        
      }
     }


 export default NavBar;
import React from "react";
import "./product.css";
import axios from "axios";
import ProductClass from "../productClass/productClass";

export class Product extends React.Component {
  constructor(props) {
    super (props);
        this.state = {
          products: [],
          images: [],
          loadingProduct: true,
          loadingImages: true
        };
  }

  async loadImages(){
    if (this.state.images.length===0) {
        let posts = [];
        let totalPosts = [];
        let hasMorePages = true;
    
        for (let pageNumber = 1; hasMorePages; pageNumber++) {
            const params = new URLSearchParams();
            params.append('per_page', 100);
            if (pageNumber > 0) {
                params.append('page', pageNumber);
            }
            
            const url = "https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/media?" + params;
            const result = await axios.get(url);
    
            posts = [...posts, result.data];
    
            const postsOnThisPage = result.data.length;
            const totalPages = result.headers["x-wp-totalpages"];
            
            if (postsOnThisPage>0) {
              result.data.map((cat)=>(totalPosts.push(cat)));
            }
  
            hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
        }
    
       await this.setState({images: totalPosts, loadingImages: false})
    }
}

  async loadProduct() {
    if (this.state.products.length===0) {
      let posts = [];
      let totalPosts = [];
      let hasMorePages = true;
  
      for (let pageNumber = 1; hasMorePages; pageNumber++) {
          const params = new URLSearchParams();
          params.append('per_page', 100);
          if (pageNumber > 0) {
              params.append('page', pageNumber);
          }
          
          const url = "https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/products?" + params;
          const result = await axios.get(url);
  
          posts = [...posts, result.data];
  
          const postsOnThisPage = result.data.length;
          const totalPages = result.headers["x-wp-totalpages"];
          
          if (postsOnThisPage>0) {
            result.data.map((cat)=>(totalPosts.push(cat)));
          }
  
          hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
      }
     await this.setState({products: totalPosts, loadingProduct: false})
    }
  }

  async componentDidMount() {
      await this.loadImages();
      await this.loadProduct();    
  }

render() {
  return(
    <div className="row">
            {this.state.loadingProduct || this.state.loadingImages ? "Loading..." : this.state.products.filter(x=>Number(x.id)==Number(this.props.match.params.productId)).map((product) => {return (<ProductClass key={product.id} productData={product} addCartItem={this.props.addCartItem} id={product.id} name={product.title.rendered} description={product.Description} image={this.state.images.filter(x=>Number(x.id)===Number(product.acf.image))[0] !== undefined ? this.state.images.filter(x=>Number(x.id)===Number(product.acf.image))[0].source_url : ""} allergies={product.acf.allergies } price={product.Price} other={product.Other}/>)})}
    </div>
)
    }
};

export default Product;
import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardImg, CardBody, CardTitle, CardText} from 'reactstrap';
import './productListingClass.css';

export class ProductListingClass extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log(this.props);
    }

    render() {
        return(
            <div className="col-md-3">
               <Card className="cardframe_frontpage">
               <Link to={"/producto/" + this.props.id} style={{ textDecoration: 'none'}}>
                    <CardImg  className="cardImage" top width="100%" src={this.props.image} alt="Card image cap" /> 
                    <CardBody className="body-listingClass">
                    <CardTitle className="linespacing" dangerouslySetInnerHTML={{__html: this.props.name}}></CardTitle>
                    <CardText>{this.props.description}</CardText>                  
                    </CardBody></Link>
                </Card>
            </div>
        )
    }
}
export default ProductListingClass;
import React from "react";
import "./avisoLegal.css";

export class AvisoLegal extends React.Component {
    constructor() {
        super();
        this.state = {
            phrase1: ""
        }
    }
    componentDidMount() {
    } 
    
    render() {
        return(
            <div className="container">
                <h1 className="head">Aviso legal</h1>
                <h2 className="head2">Identificación y contacto del propietario de la web.</h2>
                <p className="text0">Vegabonty (en adelante la web) es propiedad de Linda Stahl (en adelante el Titular) con domicilio en Calle Gomera S/N Local 6, 29640 Fuengirola(Malaga), con CIF/NIF X9591660Q teléfono 644697064 y correo electrónico <a href="mailto:info@vegabonty.com">info@vegabonty.com.</a></p>
                <h2 className="head2"> Ámbito de aplicación.</h2>
                <p className="text0">La navegación por el sitio web del Titular implica asumir la condición de usuario y la aceptación absoluta de lo manifestado en este Aviso Legal.</p>
                <h2 className="head2"> Propiedad intelectual o industrial.</h2>
                <p className="text0">Los contenidos de la Web, por ejemplo textos, imágenes, gráficos o código fuente, están protegidos por derechos de propiedad intelectual e industrial.
                    Tales contenidos no podrán ser, por ejemplo, reproducidos o transformados sin el permiso previo y explícito del Titular. Los usuarios pueden acceder a los contenidos y realizar copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen en servidores en red, ni sean objeto de ningún tipo de explotación.</p>
                <h2 className="head2">Normas comunes de uso de la web.</h2>
                <p className="text0">El usuario se compromete a hacer un uso de la Web de acuerdo a las siguientes normas:
                    <ul>
                        <li>No publicará comunicaciones comerciales a través del sitio web.</li>
                        <li>No recopilará contenido o información de otros usuarios.</li>
                        <li>No cargará virus ni código malintencionado de ningún tipo.</li> 
                        <li>No solicitará información de inicio de sesión ni accederá a una cuenta perteneciente a otro usuario.</li>
                        <li>No molestará, intimidará ni acosará a ningún usuario.</li>
                        <li>No realizará actos ilícitos, engañosos, malintencionados o discriminatorios en el sitio web, de acuerdo a estas condiciones y al ordenamiento jurídico español.</li>
                        <li>No hará nada que pueda poner en peligro la seguridad de su cuenta, especialmente respecto a su contraseña. De hecho, comunicará cualquier incidencia relacionada con su contraseña, cuenta, vulneración de la confidencialidad de tus datos, peligro o amenaza detectada en la seguridad de sus datos.</li>
                    </ul>
                    
                    El Titular podrá eliminar cualquier contenido o suspender el servicio si según su propio criterio, incumple cualquiera de las normas y obligaciones descritas en este Aviso Legal.
                <br></br>
                    Para cualquier consulta o aclaración sobre estas normas comunitarias en particular o estas condiciones en particular, puede contactarnos vía email.</p>

                <h2 className="head2">Enlaces y limitación de responsabilidad.</h2>
                <p className="text0">El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre el Titular y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte del Titular de sus contenidos o servicios. Aquellas personas que se propongan establecer un hiperenlace previamente deberán solicitar autorización por escrito al Titular. En todo caso, el hiperenlace únicamente permitirá el acceso a la página de inicio de nuestro sitio web, asimismo deberá abstenerse de realizar manifestaciones o indicaciones falsas, inexactas o incorrectas sobre el Titular, o incluir contenidos ilícitos, contrarios a las buenas costumbres y al orden público.
                    El Titular no se responsabiliza del uso que cada usuario le dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en base a los mismos. El contenido de la Web es de carácter general y meramente informativo.
                    El Titular no asumirá responsabilidad por el contenido o funcionamiento de webs de terceros a los que el usuario de la Tienda pueda acceder mediante cualquier tipo de enlace.
                    El Titular declina cualquier responsabilidad, hasta donde permita el ordenamiento jurídico, por los daños y perjuicios derivados de la presencia de virus, de las actuaciones de terceros que vulneren derechos de propiedad, al honor, a la intimidad o sobre publicidad ilícita.
                    Si algún usuario o tercero considera que en la Web se está cometiendo algún ilícito de cualquier tipo debe remitir una comunicación al Titular a la dirección de email que aparece en nuestros datos identificativos. Deberá identificarse debidamente especificando los hechos que denuncia.</p>
                <h2 className="head2">Privacidad.</h2>
                <p className="text0">Si en la Web se recabaran datos personales, el Titular será responsable de los mismos y los tratará con absoluta confidencialidad y seguridad. Podrá encontrar más información sobre nuestra política de privacidad y cookies en la página específica de información sobre esta cuestión.</p>
                <h2 className="head2">Legislación aplicable, solución de cuestiones controvertidas y foro competente.</h2>
                <p className="text0">El uso de la Web se regirá por la legislación española. Cualquier controversia que surja o guarde relación con el uso de la Web, será sometido a la jurisdicción no exclusiva de los Juzgados y Tribunales del municipio donde radica el domicilio social del Titular, salvo que la normativa en vigor o el código de conducta establezca foro distinto.</p>


                {/* <div className="legal" dangerouslySetInnerHTML={{ __html:this.state.phrase1}}></div>              */}
            </div>

        );
    };
}

export default AvisoLegal;
import React from "react";
import axios from "axios";
import Category from '../category/category';

export class CategoryListing extends React.Component {
    constructor() {
        super();
            this.state = {
                categories: [],
                images: [],
                loadingCategories: true,
                loadingImages: true
            };
    }
    
    async loadImages(){
        if (this.state.images.length===0) {
            let posts = [];
            let totalPosts = [];
            let hasMorePages = true;
        
            for (let pageNumber = 1; hasMorePages; pageNumber++) {
                const params = new URLSearchParams();
                params.append('per_page', 100);
                if (pageNumber > 0) {
                    params.append('page', pageNumber);
                }
                
                const url = "https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/media?" + params;
                const result = await axios.get(url);
        
                posts = [...posts, result.data];
        
                const postsOnThisPage = result.data.length;
                const totalPages = result.headers["x-wp-totalpages"];
                console.log(postsOnThisPage + ":" + totalPages);
                
                if (postsOnThisPage>0) {
                  result.data.map((cat)=>(totalPosts.push(cat)));
                }
      
                hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
            }
        
           await this.setState({images: totalPosts, loadingImages: false})
           console.log(totalPosts);
        }
      }

    async loadCategories(){
        if (this.state.categories.length===0) {
            let posts = [];
            let totalPosts = [];
            let hasMorePages = true;
        
            for (let pageNumber = 1; hasMorePages; pageNumber++) {
                const params = new URLSearchParams();
                params.append('per_page', 100);
                if (pageNumber > 0) {
                    params.append('page', pageNumber);
                }
                
                const url = "https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/product-category?" + params;
                const result = await axios.get(url);
        
                posts = [...posts, result.data];
        
                const postsOnThisPage = result.data.length;
                const totalPages = result.headers["x-wp-totalpages"];
                
                if (postsOnThisPage>0) {
                  result.data.map((cat)=>(totalPosts.push(cat)));
                }
      
                hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
            }
        
           await this.setState({categories: totalPosts, loadingCategories: false})
        }
    }
    
    async componentDidMount() {
        await this.loadImages();
        await this.loadCategories();
    }    

    render() {
        return(
            <div className="row"> 
                {this.state.loadingCategories || this.state.loadingImages ? "Loading..." : this.state.categories.map((category) => {return (<Category key={category.id} id={category.id} title={category.title.rendered} description={category.description} image={this.state.images.filter(x=>Number(x.id)===Number(category.acf.image))[0] !== undefined ? this.state.images.filter(x=>Number(x.id)===Number(category.acf.image))[0].source_url : ""} />)})}
            </div>
        )
    }
}
export default CategoryListing;
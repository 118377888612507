import React from "react";
import {getCMSPhrase} from "./../CMSHelper/CMSHelper";
import "./sobreNosotros.css";

export class SobreNosotros extends React.Component {
    constructor() {
        super();
        this.state = {
            pagePhrase: ""
        }
    }  
    
    async componentDidMount () {
        this.setState({pagePhrase: await getCMSPhrase(2)})
    }

    render() {        
        return(
            <div>
                <p className="contacto">Sobre nosotros!</p>
                <div className="text0">
                   {this.state.pagePhrase}
                </div>
            </div>
        );
    };
}

export default SobreNosotros;
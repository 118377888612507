import ReactHtmlParser from 'react-html-parser';

export async function getCMSPhrase(phraseId) {
    
    const response = await fetch('https://vegabontycmsone.azurewebsites.net/phrases/' + phraseId);
    const data = await response.json();
    
    return ReactHtmlParser(data.Content);

}

import React from "react";
import "./footer.css";
import fb_NY from "./../container/pictures/fb_NY.png";
import instagram from "./../container/pictures/instagram.png";


export class Footer extends React.Component {
    constructor() {
        super();
        this.state = {
            phrase1: "",
            phrase2: "",
            
        }
    }
    componentDidMount() {
    
    } 
    
    render() {
        return(
            <div className="row footerNew">
                <div className="col footerHeight desktopItem">
                    <a href="/avisolegal" className="other">Aviso legal</a> |&nbsp;
                    <a href="/cookies" className="other">Cookies</a> |&nbsp;
                    <a href="/compras" className="other">Politica de venta</a>  
                </div>       

                <div className="col footerHeight">
                    <a className="other" href="mailto:info@vegabonty.com">info@vegabonty.com</a> |&nbsp;
                    <span className="other">+34 644 697 064</span>
                </div>  

                <div className="col footerHeight">
                    <span className="other">Calle Gomera S/N Local 6, Fuengirola | BETA</span>
                </div>
            
                <div className="col footerHeight">
                    <a href="https://www.facebook.com/vegabonty/"><img className="FB" alt="Facebook" src={fb_NY}/></a>
                    <a href="https://www.instagram.com/vegabonty/"><img className="insta" alt="Instagram" src={instagram}/></a>
                </div>
            </div>

        );
    };
}

export default Footer;


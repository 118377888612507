import React from "react";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import "./contacto.css";

export class Contacto extends React.Component {

    constructor() {
        super();
        this.state = {
            pagetitle: ""
        }
    }
    componentDidMount() {        
    }

    async sendMessage () {   
        if (document.getElementById('examplNombre').value==="" || document.getElementById('exampleCorreo').value==="" || document.getElementById('exampleText').value==="") {
          alert("Habia errores con vuestro pedido. Por favor asegurese que los 3 campos del formulario esten rellenados.\n\n");
          return false;
        }
        else {
          const fullName = document.getElementById('examplNombre').value;
          const email = document.getElementById('exampleCorreo').value;
          const message = document.getElementById('exampleText').value;
          const subscribe = document.getElementById('subscribe').value;
    
          const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                fullName: fullName,
                email: email,
                message: message,
                subscribe: subscribe
            })};
            console.log(requestOptions);
            alert("Gracias por contactarnos! Nos pondremos en contacto con usted lo antes posible.\n\n");
          await fetch('https://www.vegabonty.com/sendMailer.php', requestOptions)
            .then(response => response.json())
            .then(data => {});

            alert("Gracias por contactarnos! Nos pondremos en contacto con usted lo antes posible.\n\n");
        }
      }

      async sendSubscribe () {   
        if (document.getElementById('examplNombresubscribe').value==="") {
          alert("Habia errores con vuestro pedido. Por favor asegurese que el campo del formulario esta rellenado.\n\n");
          return false;
        }
        else {
          const email = document.getElementById('examplNombresubscribe').value;
    
          const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                email: email,
            })};
            console.log(requestOptions);
            alert("Gracias por subscribirte!\n\n");
          await fetch('https://www.vegabonty.com/sendMailerSubscribe.php', requestOptions)
            .then(response => response.json())
            .then(data => {});
        }
      }

    render() {
        return(
            <div className="container">
                {/* <Link to="/contacto">Contacto</Link> */}
                <p className="contacto">Ponte en contacto con nosotros!</p>
                <Form className="contact-form">
                    <FormGroup>
                            <Label for="nombre" className="list">Nombre</Label>
                            <Input className="inputContact" type="text" name="nombre" id="examplNombre"/>
                    </FormGroup>
                    <FormGroup>
                            <Label for="correo" className="list">Correo electrónico</Label>
                            <Input className="inputContact" type="text" name="correo" id="exampleCorreo"/>
                    </FormGroup>                    
                    <FormGroup>
                            <Label for="exampleText" className="list">Mensaje</Label>
                            <Input className="inputContact" type="textarea" name="text" id="exampleText" />
                    </FormGroup>
                    <FormGroup>
                            <Input type="checkbox" id="subscribe" /><br/>
                            Inscríbete para nuestra lista de correo electrónico para recibir actualizaciones, promociones y más.
                            <br/>
                    </FormGroup>
                    <Button className="enviar btn" onClick={()=>this.sendMessage()}>Enviar</Button>
                </Form>

                <h1 className="phrase1">Conéctate con nosotros!</h1> 
                <Form className="contact-form">
                     <p className="suscribirseText">Suscríbete para recibir noticias sobre promociones, ofertas y eventos.</p>
                        <FormGroup>
                            <Label for="nombre" className="list"></Label>
                            <Input className="inputContact" placeholder="e-mail" type="nombre" name="nombre" id="examplNombresubscribe"/>
                        </FormGroup>
                <Button className="subscribir btn" onClick={()=>this.sendSubscribe()}>Enviar</Button>              
                </Form>
              
               
            </div>
        );
    };
}

export default Contacto;
import React from "react";
import "./compras.css";

export class Compras extends React.Component {
    render() {
        return(
            <div className="container">
                <h1 className="head">Política de venta</h1>
                <h1 className="head">Objeto</h1>
                <p className="text0">Estas Condiciones establecen los derechos y obligaciones de todos los usuarios de www.vegabonty.com, en relación con los productos y servicios
                    que la empresa ofrece a través de su sitio.
                    Antes de confirmar un pago para realizar un pedido, le rogamos al cliente que lea atentamente las presentes Condiciones de venta y nuestra Política de Privacidad.
                    Al utilizar la página web www.vegabonty.com o hacer un pedido a través de la misma, el cliente es consciente de quedar vinculado por las siguientes cláusulas 
                    presentes en este documento. Si el cliente no está de acuerdo con estas condiciones y con la Política de Privacidad, no debe hacer ningún pedido.
                    Estas Condiciones podrían ser modificadas, por lo que recomendamos que el cliente debe leerlas antes de efectuar cada pedido.</p>
               
                <h1 className="head">Pedidos</h1>
                <p className="text0">Al realizar un pedido en www.vegabonty.com está aceptando los precios, descripciones de los productos comprados y las condiciones generales de 
                     venta, presentes en este documento.
                    Durante el proceso del pedido, el cliente se compromete en facilitarnos de manera cierta y correcta su dirección de correo electrónico, dirección postal y otros datos
                    de contacto para poder cursar su pedido. Estos datos no se facilitarán a terceros y serán tratados según lo establece el REGLAMENTO (UE) 2016/679 de Protección 
                    de Datos. En caso de elegir pagar el pedido con su tarjeta de crédito o débito, el cliente nos garantiza que está plenamente autorizado/a para utilizar la tarjeta
                    en cuestión. Para recibir el pedido, el cliente deberá seguir el procedimiento de compra online hasta el pago, el cual puede ser mediante tarjeta de crédito o 
                    débito (a través de la plataforma segura de Sabadell) o por Bizum. Tras este paso, recibirá un correo electrónico acusando recibo de su pedido. Este correo
                     electrónico no significa que el pedido haya sido aceptado. Todos los pedidos están sujetos a nuestra aceptación. Validaremos tal aceptación enviándole un 
                     correo electrónico en el que le confirmamos que el o los productos están siendo enviados. El Contrato para la compra de productos entre Linda Stahl y el 
                     cliente quedará formalizado únicamente cuando le enviemos la confirmación de envío. Serán objeto del Contrato únicamente aquellos productos relacionados en 
                     la confirmación de envío. Linda Stahl no estará obligada a suministrarle ningún otro producto que pudiera haber sido objeto de pedido hasta que le confirme el 
                     envío del mismo en una confirmación de envío independiente.</p>
                  
                <h2 className="head2">Pastelería artesanal </h2>
                <p className="text0">Debido a las peculiaridades de los productos de pastelería artesanal Vegabonty no se compromete a entregarlos de forma idéntica a la 
                    representada en las fotografías expuestas en la web. Vegabonty procurará entregar los productos seleccionados de la sección de pastelería artesanal con el 
                    mayor grado de parecido posible a los mostrados en la imagen, teniendo en cuenta que se trata de productos de elaboración única y manual. Los productos de la 
                    Vegabonty pueden contener trazas de frutos secos (con o sin cáscara), gluten y azúcares, por lo que no son aptos para personas que tengan restricciones o 
                    limitaciones en el consumo de este tipo de alimentos. Se recomienda, y queda bajo la responsabilidad del usuario, leer la información específica del contenido o 
                    composición de cada producto, exonerando de cualquier culpa o responsabilidad al sitio web y a Vegabonty.</p>

                <h2 className="head2">Disponibilidad de los productos </h2>
                <p className="text0">Todos los productos ofrecidos en nuestra tienda on-line estarán disponibles, salvo que se hubieran agotado y no hubiera sido posible 
                    actualizar la información de la página web. En aquellos casos en los que se formalice una compra que incluya un producto agotado, contactaremos lo antes posible 
                    para proponerle sustituir el producto por otro de características y precios similares, o eliminarlo de la lista de productos elegidos y reembolsarle el importe del
                    mismo. En cuanto a los productos de pastelería artesanal hay que considerar que se trata de productos de elaboración manual y, por lo tanto, de unidades de 
                    producción limitadas a fin de mantener el frescor y las características aptas para el consumo, por lo que en un momento determinado puede que nos encontremos sin 
                    disponibilidad del mismo.</p>

                <h2 className="head2">Devoluciones y cambios </h2>
                <p className="text0">Trabajamos con productos perecederos por lo que debido a la característica especial de los productos de Vegabonty no será posible realizar una
                    devolución de los mismos una vez le hayan sido entregados en su domicilio. En el momento de entrega de su pedido, deberá comprobar que los productos entregados se 
                    corresponden con el pedido realizado a través de la web www.vegabonty.com. Vegabonty (Linda Stahl, X9591660Q)no será bajo ninguna circunstancia responsable de 
                    un uso inadecuado de sus productos. No ofrecemos devoluciones ni cambios, pero si pasara algo con tu pedido, por favor contáctanos en info@vegabonty.com.</p>

                <h2 className="head2">He recibido un producto erróneo o dañado. ¿Qué hago? </h2>
                <p className="text0">Lo sentimos si tu producto ha llegado dañado. Para resolverlo lo antes posible, envíanos un email a &nbsp; 
                    <a href="mailto:info@vegabonty.com">info@vegabonty.com</a> ¡Te contactaremos con una solución lo antes posible!</p>

                <h2 className="head2">Política de entrega de mercancía</h2>
                <h2 className="head2">Envios</h2>
                <p className="text0">¿Cuándo recibiré mi pedido?
                    Debido a la situación actual en la que nos encontramos por el COVID-19, hemos tomado las medidas necesarias de higiene para la entrega de nuestros productos. 
                    Los pedidos tomados se entregarán los LUNES A VIERNES. Es decir, si has hecho tu pedido antes de las 10:00h lo recibirás al día siguiente de los 2 días de encargo.
                    ¿Desde dónde se envía mi pedido? Los pedidos serán entregados por SEUR siguiendo todas las normas de higiene necesarias para no poner en riesgo el bienestar de
                    nuestros trabajadores ni el de nuestros clientes. Es muy importante que se compruebe la dirección donde desea recibir su pedido. Todos nuestros pedidos salen de
                    nuestra fábrica siguiendo la normativa de higiene vigente. Si tiene alguna duda puede ponerse en contacto con nosotros a través de  &nbsp;
                    <a href="mailto:info@vegabonty.com">info@vegabonty.com</a> o a nuestros teléfono 644697064. OPCIÓN RECOGIDA EN EL OBRADOR: Los pedidos marcados como
                     RECOGIDA EN OBRADOR, podrán ser retirados de nuestras instalaciones en : Calle Gomera 
                    SN Local 6, Fuengirola, Malaga 29640. Los días de reparto Lunes a Domingo, Martes cerrado.</p>
            </div>
    
    
        );
    };
}

export default Compras;
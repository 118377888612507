import React from "react";
import "./cart.css";
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

export class Cart extends React.Component {
  constructor(props) {
    super (props);

    this.updateTotalAmount = this.updateTotalAmount.bind(this);
    this.updateTotalPrice = this.updateTotalPrice.bind(this); 
    this.addCartItem = this.addCartItem.bind(this);
    this.removeCartItem = this.removeCartItem.bind(this);  
    this.renderCartRow = this.renderCartRow.bind(this); 
    this.workWithMe = this.workWithMe.bind(this);
    this.retrieveCartData = this.retrieveCartData.bind(this);
    this.populateCart = this.populateCart.bind(this);    
    
    this.state = {
      products: [],
      totalAmount: 0,
      totalPrice: 0
    };    
  }

  async componentDidMount() {    
    this.populateCart();
  }

  async retrieveCartData() {     
  
      const data = Promise.all(
        this.props.cartData.filter(num => num.amount > 0).map(async (i) => await (await fetch(`https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/products/${i.id}`, {
              method: "GET",
              headers: {
                  "Content-Type": "application/json"
              }
          })).json())
      )
      
      return data
  };
    
  async renderCartRow() {      
      var retrievedCartData = await this.retrieveCartData();
      
      if (retrievedCartData!=null) {
        return this.props.cartData.filter(num => num.amount > 0).map((currentRow) => {
          return this.workWithMe(currentRow,retrievedCartData);
        });
      }
  }

  workWithMe (currentRow, productDataArray) 
  {
    if (!productDataArray) { return false; }

    const productData = productDataArray.filter(num => num.id === currentRow.id)[0];

    // if (currentRow.size===1) {this.updateTotalPrice(Number(productData.acf.price1.replace(",","."))*Number(currentRow.amount));console.log("test 1")} 
    // if (currentRow.size===2) {this.updateTotalPrice(Number(productData.acf.price2.replace(",","."))*Number(currentRow.amount));console.log("test 2")}
    // if (currentRow.size===3) {this.updateTotalPrice(Number(productData.acf.price3.replace(",","."))*Number(currentRow.amount));console.log("test 3")}

    let finalPrizeText = "";
    let finalPrize = 0;

    if (currentRow.size===1) {
      finalPrizeText = productData.acf.price1text ? "(" + productData.acf.price1text + ")" : "";
      finalPrize = productData.acf.price1;
    }
    if (currentRow.size===2) {
      finalPrizeText = productData.acf.price2text ? "(" + productData.acf.price2text + ")" : "";
      finalPrize = productData.acf.price2;
    }
    if (currentRow.size===3) {
      finalPrizeText = productData.acf.price3text ? "(" + productData.acf.price3text + ")" : "";
      finalPrize = productData.acf.price3;
    }

    this.updateTotalPrice(Number(Number(finalPrize.replace(",","."))*Number(currentRow.amount)).toFixed(2));

    const rowStyle = "row"; //amount > 0 ? "row" : "hiddenRow";

    return (<div className={rowStyle} key={productData.id + "_" + currentRow.size}>
      <div className="col-6 cartName"><Link to={"/producto/" + productData.id} style={{ textDecoration: 'none'}} dangerouslySetInnerHTML={{__html: productData.title.rendered + " " + (finalPrizeText === null ? "" : finalPrizeText) }}></Link></div>
      <div className="col-2">{finalPrize.replace(",",".")}€</div>
      <div className="col-2">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 nopadding">
            <div className="knapp" onClick={()=>this.removeCartItem(productData.id, currentRow.size)}>-</div>
            <div className="noknapp">{currentRow.amount}</div>
            <div className="knapp" onClick={()=>this.addCartItem(productData.id, currentRow.size)}>+</div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <div className="col-2">{Number(Number(finalPrize.replace(",","."))*Number(currentRow.amount)).toFixed(2)}€</div>
    </div>);
  }

  updateTotalPrice (totalPrice) {
    console.log("Price: " + totalPrice);
    if(Number(totalPrice)>0) {
      console.log(Number(this.state.totalPrice) + "+ " + Number(totalPrice));
      this.setState({totalPrice: Number(this.state.totalPrice)+Number(totalPrice)});
    }  
  }

  async addCartItem(productId,size) {
    console.log(productId,size)
    this.props.addCartItem(productId,size,1);
    toast('1 cosita añadida a tu carrito!'); 
    this.populateCart();
  }

  async removeCartItem(productId,size) {
    this.props.removeCartItem(productId,size);
    toast('1 cosita quitado de tu carrito!');  
    this.populateCart();
  }
  
  updateTotalAmount() {
    this.setState({totalAmount: this.props.cartData.filter(num => num.amount > 0).reduce((total, currentValue) => total = total + currentValue.amount,0)});    
  }

  async populateCart() {   
    this.updateTotalAmount();
    this.setState({totalPrice: 0});
    this.setState({products: await this.renderCartRow()});    
  }

  render() {
      
  return(
    <div className="container"><Toaster />
      <p className="contacto">Tus cositas!</p>
      <div className="row"> 
        <div className="col-6 cartHeaderName">Cosita</div>
        <div className="col-2 cartHeader">Precio/unidad</div>
        <div className="col-2 cartHeader">Cantidad</div>
        <div className="col-2 cartHeader">Total</div> 
      </div>
      {this.state.products}
      <div className="row"> 
        <div className="col-6 cartHeaderName">{this.props.cartData.filter(num => num.amount > 0).length} tipo(s) de cositas</div>
        <div className="col-2 cartHeader">&nbsp;</div>
        <div className="col-2 cartHeader">{Number(this.state.totalAmount).toFixed(2)}</div>
        <div className="col-2 cartHeader">{Number(this.state.totalPrice).toFixed(2)} €</div> 
      </div>
      <p></p> 
      <Link className="checkout" to={"checkout"}>Finalizar compra!</Link>
    </div>
)
    }
};

export default Cart;
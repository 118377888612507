import React from "react";
import "./checkoutClass.css";

export class CheckoutClass extends React.Component {
  constructor(props) {
    super (props);

        this.state = {
          productData: [],
          amount: this.props.amount
          };
  }

  fetchProductData(productId) {
    let newProductId = "" + productId;
    newProductId = newProductId.replace('-1','');
    newProductId = newProductId.replace('-2','');
    newProductId = newProductId.replace('-3','');

    fetch('https://vegabontycmsone.azurewebsites.net/products/' + newProductId)
    .then(response => response.json())
    .then(data => { 
      let productData = data; 
      this.setState({productData: productData}); 
      if (this.props.size===1) {this.props.updateTotalAmount(productData.Price1*this.props.amount)} 
      if (this.props.size===2) {this.props.updateTotalAmount(productData.Price2*this.props.amount)}
      if (this.props.size===3) {this.props.updateTotalAmount(productData.Price3*this.props.amount)}
    })
  }

  componentDidMount() {
    this.fetchProductData(this.props.productId);
  }

render() {
  let finalPrizeText = "";
  let finalPrize = 0;

  if (this.props.size===1) {
    finalPrizeText = this.state.productData.Price1Text ? " (" + this.state.productData.Price1Text + ")" : "";
    finalPrize = this.state.productData.Price1;
  }
  if (this.props.size===2) {
    finalPrizeText = this.state.productData.Price2Text ? " (" + this.state.productData.Price2Text + ")" : "";
    finalPrize = this.state.productData.Price2;
  }
  if (this.props.size===3) {
    finalPrizeText = this.state.productData.Price3Text ? " (" + this.state.productData.Price3Text + ")" : "";
    finalPrize = this.state.productData.Price3;
  }

  return(
    <div>
      <div className="row" key={this.props.productId}> 
        <div className="col-9 cartName">{this.state.productData.Name + finalPrizeText + " x " + this.state.amount}</div>
        <div className="col-3 checkoutCosts">{Number((finalPrize*this.state.amount)).toFixed(2)}€</div>
      </div>
    </div>
    
)
    }
};

export default CheckoutClass;
import React from "react";
import axios from "axios";
import "./productListing.css";
import ProductListingClass from "../productListingClass/productListingClass";

export class ProductListing extends React.Component {
  constructor() {
    super();
        this.state = {
            products: [],
            images: [],
            loadingProducts: true,
            loadingImages: true
        };
}

async loadImages(){
  if (this.state.images.length===0) {
      let posts = [];
      let totalPosts = [];
      let hasMorePages = true;
  
      for (let pageNumber = 1; hasMorePages; pageNumber++) {
          const params = new URLSearchParams();
          params.append('per_page', 100);
          if (pageNumber > 0) {
              params.append('page', pageNumber);
          }
          
          const url = "https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/media?" + params;
          const result = await axios.get(url);
  
          posts = [...posts, result.data];
  
          const postsOnThisPage = result.data.length;
          const totalPages = result.headers["x-wp-totalpages"];
          
          if (postsOnThisPage>0) {
            result.data.map((cat)=>(totalPosts.push(cat)));
          }

          hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
      }
  
     await this.setState({images: totalPosts, loadingImages: false})
  }
}

async loadProducts(){
  if (this.state.products.length===0) {
    let posts = [];
    let totalPosts = [];
    let hasMorePages = true;

    for (let pageNumber = 1; hasMorePages; pageNumber++) {
        const params = new URLSearchParams();
        params.append('per_page', 100);
        if (pageNumber > 0) {
            params.append('page', pageNumber);
        }
        
        const url = "https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/products?" + params;
        const result = await axios.get(url);

        posts = [...posts, result.data];

        const postsOnThisPage = result.data.length;
        const totalPages = result.headers["x-wp-totalpages"];
        
        if (postsOnThisPage>0) {
          result.data.map((cat)=>(totalPosts.push(cat)));
        }

        hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
    }
   await this.setState({products: totalPosts, loadingProducts: false})
  }
}

runTheSearch(){

  if (this.state.loadingProducts || this.state.loadingImages) { return "Loading..." }   
  
  if (this.props.match.params.categoryName==="buscar") {
    return this.state.products.filter(x=>x.title.rendered.toLowerCase().includes(this.props.match.params.categoryId.toLowerCase()))
    .map((product) => {
      return (<ProductListingClass 
        key={product.id} 
        id={product.id} 
        name={product.title.rendered} 
        other={product.acf.other} 
        allergies={product.acf.allergies} 
        image={this.state.images.filter(x=>Number(x.id)===Number(product.acf.image))[0] !== undefined ? 
          this.state.images.filter(x=>Number(x.id)===Number(product.acf.image))[0].source_url : ""} />)})
  }
  else {
    return this.state.products.filter(x=>Number(x.acf["product-category"][0])===Number(this.props.match.params.categoryId)).map((product) => {return (<ProductListingClass key={product.id} id={product.id} name={product.title.rendered} other={product.acf.other} allergies={product.acf.allergies} image={this.state.images.filter(x=>Number(x.id)===Number(product.acf.image))[0] !== undefined ? this.state.images.filter(x=>Number(x.id)===Number(product.acf.image))[0].source_url : ""} />)})
  }
}

async componentDidMount() {
    await this.loadImages();
    await this.loadProducts();    
}

render() {
  return(
    <div className="container">
        <div className="homeCategoryListing">                
          <div className="row "> 
          {this.runTheSearch()}
        </div>
      </div>
    </div>
)
    }
};

export default ProductListing;
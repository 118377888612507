import React from "react";
import axios from "axios";
import "./galeriaListing.css";
import GaleriaClass from "./../galeriaClass/galeriaClass";

export class GaleriaListing extends React.Component {
    constructor() {
        super();
            this.state = {
                products: [],
                images: [],
                loadingProducts: true,
                loadingImages: true
            };
    }
    
    async loadImages(){
        if (this.state.images.length===0) {
            let posts = [];
            let totalPosts = [];
            let hasMorePages = true;
        
            for (let pageNumber = 1; hasMorePages; pageNumber++) {
                const params = new URLSearchParams();
                params.append('per_page', 100);
                if (pageNumber > 0) {
                    params.append('page', pageNumber);
                }
                
                const url = "https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/media?" + params;
                const result = await axios.get(url);
        
                posts = [...posts, result.data];
        
                const postsOnThisPage = result.data.length;
                const totalPages = result.headers["x-wp-totalpages"];
                
                if (postsOnThisPage>0) {
                  result.data.map((cat)=>(totalPosts.push(cat)));
                }
      
                hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
            }
        
           await this.setState({images: totalPosts, loadingImages: false})
        }
    }
    
    async loadProducts(){
        fetch('https://vegabonty.com/wordpress/index.php/wp-json/wp/v2/gallery-pictures/')
            .then(response => response.json())
            .then(data => {this.setState({products: data, loadingProducts: false});console.log(data);})
    }
    
    async componentDidMount() {
        await this.loadImages();
        await this.loadProducts();    
    }

    render() {
        return(
            <div className="container">
                <div className="homeCategoryListing">
                    <div className="row "> 
                        {this.state.loadingProducts || this.state.loadingImages ? "Loading..." : this.state.products.map((galleryPicture) => {return (<GaleriaClass name={galleryPicture.title.rendered} image={this.state.images.filter(x=>Number(x.id)===Number(galleryPicture.acf.image))[0].source_url} />)})}
                    </div>
                </div>
            </div>
        )
    }
}                     
export default GaleriaListing;

